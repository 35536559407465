/*tui-css*/
.face-conversation .profile{
    display: none;
}
.tui-conversation {
    height: 100%;
    min-width: 360px;
    max-width: 400px;
    width: 100%;
    /*width: 30%;*/
    display: flex;
    flex-direction: column;
    position: relative;
    border-right: 1px solid #F9FAFB;
    text-align: initial;
}
.tui-conversation-header {
    display: flex;
    padding: 10px 20px;
}
.tui-conversation-create-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    display: none !important;
}
.no-result {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.no-result-icon {
    margin: 100px auto 50px;
}
.no-result-message {
    color: #999999;
    font-weight: 400;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    line-height: 22px;
}
.chat-tuikit{
    height: 100%;
}
.chat-content{
    display: flex;
    width: calc(100% - 70px);
    overflow-x: auto;
}
.chat-item{
    flex: 1;
    border:  1px solid #eff0f2;
    border-top: 0;
    border-bottom: 0;
    /*height: 100vh;*/
    overflow-y: hidden;
    min-width: 375px;
    position: relative;
}
.chat-item path{
    fill: rgba(0,0,0,0.8);
}
.chat-item .chat{
    background:#f8f8f8;
}
.chat-header{
    background: #ffffff;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    height: 32px;
}
.custom-tui-chat-header{
    padding: 10px 12px;
}
.custom-tui-chat-header-right{
    display: flex;
    flex: 0 0 50px;
    justify-content: space-between;
}
.custom-tui-chat-header-right path{
    color: rgba(0,0,0,0.8);
}
.qr-pop-content{
    min-width: 200px;
    min-height: 360px;
    right: 0;
    top: 30px;
    background: #FFFFFF;
}
.qr-inner{
    padding: 20px;
    text-align: center;
}
.qr-inner .tui-kit-avatar{
    margin: 0 auto;
}
.qr-inner-name{
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    color: #1F1F1F;
}
.qr-inner-qrimg{
    width: 180px;
    height: 180px;
    margin: 10px 0;
}
.qr-inner-qrimg img{
    width: 100%;
    height: auto;
}
.qr-inner-button{
    background: rgba(31,31,31,1);
    color: rgba(182,244,11,1);
    font-size: 12px;
    font-weight: 700;
    padding: 15px 0;
    border-radius: 10px;
    width: 120px;
    margin: 0 auto;
}

.edit-profile-content{
    width: 360px;
    right: 0;
    top: 65px;
    border-radius: 0;
    background: #fff;
    height: calc(100vh - 65px);
    box-shadow: 0 0 1px rgba(0,0,0,.25);
}
.edit-profile-inner{
    width: 100%;
    height: 100%;
}
.tui-profile-div-with-edit{
    color: rgba(0,0,0,.6);
}
/*=====mute status======*/
.mute-status{
    position: relative;
}
.mute-status:after{
    background: rgba(255,255,255,0.2);
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.mute-status-icon{
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    background: #FFF;
    border-radius: 50%;
}
/*====ACTIVE MODE=====*/
.chat-active{
    border: 4px solid #b6f40b;
}
.chat-active .chat-header{
    background: #1F1F1F;
    color: #FFFFFF;
}
.chat-item:not(.chat-active) .tui-message-input{
    display: none;
}
.chat-active .tui-message-input{
    background: #FFFFFF;
}
.chat-active .tui-chat-header path{
    fill: rgba(255,255,255,1);
}
.chat-active .edit-profile-inner path{
    fill: rgba(0,0,0,1);
}

/*=====MESSAGE LIST========*/
.face-message .message-custom, .message-text{
    border: 0;
    background: #FFFFFF;
}
.face-message .message-status .time{
    display: none;
}

.face-message .bubble-out:not(img){
    background: rgba(178, 244, 23, 0.40);
    padding: 10px;
}
.face-message-status{
    margin-bottom: 20px;
}
.face-message .message-custom, .message-audio {
    align-items: center;
    display: flex;
    background: #ffffff;
    padding: 10px;
    border-radius: 16px 16px 16px 0;
}
/*=========CHAT==============*/
.face-input-plugin{
    display: flex;
    width: 60px;
}
.tui-message-input-main{
    position: relative;
}
.plugin-item .tui-kit-icon {
    z-index: 2;
}
/*========File PreUpload==============*/
.file-list{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
    padding: 0 10px;
    overflow-x: auto;
    height: 100%;
    background: #FFFFFF;
}
.file-list-item{
    position: relative;
    padding: 10px;
}
.file-list-img-item,.file-list-video-item{
    width: 88px;
    height: 88px;
    border-radius: 10px;
}
.file-list-file-item{
    max-width: 176px;
    height: 68px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #E8E8E8;
    gap: 12px;
    padding: 10px 20px;
    font-size: 14px;
}
.file-list-icon{
    background: #1F1F1F;
}
.file-list-icon path{
    fill: #b6f40b;
}
.file-list-close{
    background: #1F1F1F;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    border: 3px solid #fff
}
.file-list-close path{
    fill: #FFFFFF;
}
.transmitter-files{
    border-radius: 50%;
    background: #b6f40b;
    padding: 10px;
    margin-left: 10px;
}
/*=====READ STATUS=======*/
.out{
    position: relative;
}
.read-status {
    color: #8f8f8f;
    font-size: 12px;
    position: absolute;
    bottom: -15px;
    right: 40px;
}
/*=====AtMembers=======*/
.face-at-list{
    padding:10px;
    max-height: 200px;
    overflow-y: auto;
    width: 150px;
}
.face-at-list-item{
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.face-at-list-item:hover{
    background: #8f8f8f09;
    border-radius: 5px;
}
.face-at-list-item span{
    font-size: 14px;
}
.pop-pos{
    position: absolute;
    top: 29px;
    left: 100px;
}
.divider{
    font-size: 12px;
    color: #8f8f8f;
    padding: 10px 0;
    line-height: 1;
}