
.face-profile{
    flex: 0 0 70px;
    height: 100vh;
    background: rgba(31,31,31,1);
    color: rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}
.face-profile-logo{
    background: rgba(182,244,11,1);
    border-radius: 10px;
    width: 45px;
    height: 45px;
    margin: 0 auto;
    margin-top: 20px;
}
.face-profile-logo .tui-kit-icon{
    padding-top: 11px;
}
.face-profile-logo .tui-kit-icon path{
    fill:#1F1F1F;
}
.face-profile-userInfo{
    text-align: center;
    margin: 30px 0 0 0 ;
}
.face-profile-userInfo .face-profile-avatar{
    width: 50px;
    height: 50px;
    border-radius: 13px;
    border: 2px solid rgba(182,244,11,1);
    margin: 0 auto;
    margin-bottom: 10px;
}
.face-profile-exit{
    margin: 0 0 20px;
    position: relative;
}
.middle-icon{
    margin:0 auto;
}
.setting-menu{
    padding: 10px;
    height: 130px;
    border-radius: 10px;
}
.face-profile path{
    fill: white;
}