.silence{
    background: #ffffff;
    width: 750px;
    height: 640px;
    /*min-height: 200px;*/
    /*max-height: 80%;*/
}
.silence-content{
    overflow-y: auto;
    height: 100%;
}
.flex-content{
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding:0 16px 20px;
    border-bottom:1px solid #E8E8E8;
}
.flex-content-average{
    flex: 1;
}
.silence-all{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 0;
}
.silence-member{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    align-content: flex-start;
    box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.08);
    border-radius: 8px;
    margin: 24px;
    padding: 35px 20px;
    height: calc(640px - 258px);
    gap: 20px;

}
.silence-member-item{
    flex:0 0 60px;
    text-align: center;
    position: relative !important;
    height: 90px;
}
.silence-member-item .tui-kit-avatar{
    margin: 0 auto;
}
.silence-member-item-title{
    line-height: 1.5;
    font-size: 14px;
    margin-top: 5px;
}
.mute-status:after{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.3);
    z-index: 2;
}
.mute-status path{
    fill: rgba(255,0,0,0.8);
}
.silence-header path{
    fill: rgba(0,0,0,0.8) !important;
}
