.hori-verti-md{
    /*element in the middle*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.qr-content{
    width: 400px;
    height: 500px;
    background: rgba(255,255,255,1);
    margin: auto;
    position: relative;
}
.qr-content:before{
    border: 1px solid #fff;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: -1;
    content: '';
    width: 400px;
    height: 500px;
}
.qr-img{
    position: relative;
    text-align: center;
    padding: 80px 15px 30px;
}
.qr-img img{
    width: 210px;
    height: 210px;
}
.qr-img-loading,.qr-mask{
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.6);
    position: absolute;
    top: 0;
    left:0;
}
.qr-img-loading{
    z-index: 3;
}
.qr-img-loading img{
    width: 100%;
    height: auto;
}
.qr-icon{
    padding: 40px;
    background: #FFFFFF;
    border-radius: 50%;
}
.qr-icon path{
    fill: #1F1F1F;
}
.qr-describe{
    text-align: center;
}
.qr-describe h4{
    font-size: 24px;
    line-height: 1.5;
}
.qr-describe p{
    font-size: 18px;
    color:#b5b5b5;
    line-height: 24px;
}